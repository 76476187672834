
<template>
    <div>
        <st-data-table
            :items="beneficiaries"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :serverSideSorting="false"
            @edit="editBeneficiary"
            @delete="deleteBeneficiary"
            responsive="sm"
            hover
        >
        </st-data-table>

         <beneficiary-modal
                ref="beneficiaryModal"
                :item="selectedEntity"
                @processBeneficiary="processBeneficiary"
                @cancel="cancel"
            ></beneficiary-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { BeneficiaryModel } from "@/modules/beneficiaries/models/beneficiary-model";
import  BeneficiaryModal from "@/modules/beneficiaries/components/BeneficiaryModal";

const { fields } = BeneficiaryModel;

export default {
    name: 'BeneficiariesTabel',
    components: { 
        BeneficiaryModal
    },
    data() {
        return {
            presenter: BeneficiaryModel.presenter,
            fields: [
                fields.full_name.extendField({ bold: true, sortable: true  }),
                fields.locality.extendField({ sortable: true  }),
                fields.user_type.extendField({ sortable: true  }),
                {
                    key:'Actions',
                    label: this.$t('BENEFICIARIES.LIST.ACTIONS'),
                },
            ],
            items: [],
            selectedEntity: null,
            selectedEntityIndex: null,
        }
    },
    methods: {
        ...mapActions({
            selectBeneficiary: 'beneficiaries/form/selectBeneficiary',
            removeBeneficiary: 'beneficiaries/form/deleteBeneficiary'
            
        }),
        async editBeneficiary(item, index) {
            this.selectedEntity = item.item;
            this.selectedEntity.index = index;
            this.selectedEntityIndex = index;
            //this.selectedBeneficiary = item.item;
            await this.selectBeneficiary(item.item);
            this.$refs.beneficiaryModal.show();
            
        },
        async deleteBeneficiary(item) {
           this.removeBeneficiary(item.item.id)
                .then(() => {
                    this.$emit('updateBeneficiary');
                    this.$alert({
                        type: 'success',
                        text: this.$t('BENEFICIARIES.SUCCESS.DELETE'),
                        cancelButtonText: this.$t('GENERAL.CLOSE'),
                        showCancelButton: true,
                        showConfirmButton: false,
                    });
                })
        },
        processBeneficiary(data) {
        },
        openAddBeneficiaryModal() {
            this.$refs.addBeneficiaryModal.show();
        },
        cancel() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
    },
    computed: {
        ...mapGetters({
            beneficiaries: 'beneficiaries/list/beneficiaries',
            permissions: 'beneficiaries/list/permissions',
            selectedBeneficiary: 'beneficiaries/form/selectedBeneficiary',
        }),
        actions() {
            return [
                {
                    name: 'edit',
                    icon: 'edit',
                    tooltipText: this.$t('BENEFICIARIES.LIST.EDIT'),
                    customIcon: false,
                    type: 'primary',
                    disabled: false //!this.permissions.hasPermissionToEdit,
                },

                 {
                    name: 'delete',
                    icon: 'trash',
                    tooltipText: this.$t('BENEFICIARIES.LIST.DELETE'),
                    customIcon: false,
                    type: 'primary',
                    disabled: false //!this.permissions.hasPermissionToEdit,
                },
            ]
        }
    },
}
</script>
